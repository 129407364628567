<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row stretch-card">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('ProSync Reports') }}</h4>
                    </div>
                    <div class="card-body new-card-body">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 stretch-card date-form new-date-form">
                                <b-form-group id="input-group-1" :label="$t('date-from')" label-for="input-1" class="m-col">
                                    <b-form-input id="input-1" v-model="serverParams.date_from" type="date" placeholder="Date From" required></b-form-input>
                                </b-form-group>
    
                                <b-form-group id="input-group-1" :label="$t('date-to')" label-for="input-1" class="ml-1 m-col">
                                    <b-form-input id="input-1" v-model="serverParams.date_to" type="date" placeholder="Date To" required></b-form-input>
                                </b-form-group>
    
                                <div class="col-lg-2 col-md-3 new-col m-col">
                                    <b-form-group :label="$t('select-category')" label-for="category_id">
                                        <b-form-select v-model="$v.serverParams.category_id.$model" :state="$v.serverParams.category_id.$dirty ? !$v.serverParams.category_id.$error : null" aria-describedby="input-1-live-feedback" class="mat-select" :options="categories" value-field="id" text-field="name" @change="onCategoryChange()">
                                            <template #first>
                                                    <b-form-select-option v-if="categories.length !== 0" value="all" selected>{{ $t('all')}}</b-form-select-option>
                                                    <b-form-select-option v-if="categories.length === 0" :disabled = true>{{ $t('no-category-found') }}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback id="input-1-live-feedback"> {{ $t("field-required") }} </b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2 col-md-3 new-col m-col">
                                    <b-form-group :label="$t('select-location')" label-for="location_id">
                                        <b-form-select class="mat-select" v-model="serverParams.location_id" :options="locations" value-field="id" text-field="name">
                                            <template #first>
                                                <b-form-select-option v-if="locations.length !== 0" value="" selected>
                                                    {{ $t('all')}}</b-form-select-option>
                                                    <b-form-select-option v-if="locations.length === 0" :disabled = true>{{ $t('no-location-found') }}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2 col-md-3 new-col m-col">
                                    <b-form-group :label="$t('Project (Survey Name)')" label-for="project_name">
                                        <b-form-select 
                                        class="mat-select"
                                        v-model="serverParams.selectedProjectId" 
                                        aria-describedby="input-1-live-feedback" 
                                        :options="projectOptions" 
                                        value-field="id" 
                                        text-field="name"
                                        @change="onProjectChange"
                                        >
                                        <template #first>
                                            <b-form-select-option v-if="projectOptions.length !== 0" value="" selected>{{ $t('all')}}</b-form-select-option>
                                            <b-form-select-option v-if="projectOptions.length === 0" :disabled="true">
                                                {{ $t('No Project Found') }}
                                            </b-form-select-option>
                                        </template>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2 col-md-3 new-col m-col">
                                    <b-form-group :label="$t('Effort')" label-for="spot_type_id">
                                        <b-form-select class="mat-select" v-model="serverParams.spot_type_id" :options="spotTypes" value-field="id" text-field="name">
                                            <template #first>
                                                <b-form-select-option v-if="spotTypes.length !== 0" value="" selected>
                                                    {{ $t('all')}}</b-form-select-option>
                                                    <b-form-select-option v-if="spotTypes.length === 0" :disabled = true>{{ $t('no-spot-type-found') }}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <!--<div class="col-lg-2 col-md-3 new-col m-col" v-if="this.version_id == 2 || this.version_id == 3">
                                    <b-form-group :label="$t('survey')" label-for="survey_id">
                                        <b-form-select class="mat-select" v-model="serverParams.survey_id" :options="surveys" value-field="id" text-field="name">
                                            <template #first>
                                                <b-form-select-option v-if="surveys.length !== 0" value="" selected>
                                                    {{ $t('all')}}</b-form-select-option>
                                                    <b-form-select-option v-if="surveys.length === 0" :disabled = true>{{ $t('No Survey Found') }}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-form-group>
                                </div> -->
                                <!-- <div class="col-lg-3 col-md-6 new-col m-col" v-if="this.version_id == 1">
                                    <div>
                                        <b-form-checkbox
                                            class="mt-3"
                                            v-model="serverParams.question_data"
                                            name="question_data"
                                            value="yes"
                                            unchecked-value="no">
                                            {{ $t('include-question-data') }}
                                        </b-form-checkbox>
                                    </div>
                                    <div>
                                        <b-form-checkbox
                                            class="mt-1"
                                            v-model="serverParams.post_process"
                                            name="post_process"
                                            value="yes"
                                            unchecked-value="no">
                                            {{$t('include-post-process-data')}}
                                        </b-form-checkbox>
                                    </div>
                                    <div v-if="this.version_id == 3">
                                        <b-form-checkbox
                                            class="mt-1"
                                            v-model="serverParams.prosync_weather_data"
                                            name="prosync_weather_data"
                                            value="yes"
                                            unchecked-value="no">
                                            {{$t('include-weather-data')}}
                                        </b-form-checkbox>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="row" v-if="this.version_id == 2 || this.version_id == 3">
                            <div class="col-lg-3 col-md-6 new-col m-col">
                                <div>
                                    <b-form-checkbox
                                        class="mt-3"
                                        v-model="serverParams.question_data"
                                        name="question_data"
                                        value="yes"
                                        unchecked-value="no">
                                        {{ $t('include-question-data') }}
                                    </b-form-checkbox>
                                </div>
                                <!-- <div>
                                    <b-form-checkbox
                                        class="mt-1"
                                        v-model="serverParams.post_process"
                                        name="post_process"
                                        value="yes"
                                        unchecked-value="no">
                                        {{$t('include-post-process-data')}}
                                    </b-form-checkbox>
                                </div> -->
                                <div v-if="this.version_id == 2 || this.version_id == 3">
                                    <b-form-checkbox
                                        class="mt-1"
                                        v-model="serverParams.prosync_weather_data"
                                        name="prosync_weather_data"
                                        value="yes"
                                        unchecked-value="no">
                                        {{$t('include-weather-data')}}
                                    </b-form-checkbox>
                                </div>
                                <div v-if="this.version_id == 2 || this.version_id == 3">
                                    <b-form-checkbox
                                        class="mt-1"
                                        v-model="serverParams.prosync_efforts_data"
                                        name="prosync_efforts_data"
                                        value="yes"
                                        unchecked-value="no">
                                        {{$t('include-efforts-data')}}
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="row float-right mt-3">
                            <div class="ml-2 mt-2">
                                <button :disabled="isLoading2" @click="getReportsData('filter')" class="btn btn-info text-white r-btn">
                                    <template v-if="isLoading2">
                                        <i class="fas fa-spinner fa-spin"></i>{{$t('filtering')}}...
                                    </template>
                                    <template v-else>
                                       {{$t('filter-btn')}}
                                    </template>
                                </button>
                            </div>
                            <!-- <div class="ml-2 mt-2" v-if="(serverParams.category_id !== 'all' && serverParams.category_id)">
                                <button :disabled="isDownImageTemplate" @click="downloadImagesUploadData()" class="btn btn-info text-white r-btn">
                                    <template v-if="isDownImageTemplate">
                                        <i class="fas fa-spinner fa-spin"></i> {{$t('downloading')}}...
                                    </template>
                                    <template v-else>
                                        {{ $t('download-images-template') }}
                                    </template>
                                </button>
                            </div> -->
                            <div class="ml-2 mt-2" v-if="canDownload || serverParams.category_id === 'all' || serverParams.category_id ">
                                <button :disabled="isLoading3" @click="downloadReportData()" class="btn btn-info text-white r-btn">
                                    <template v-if="isLoading3">
                                        <i class="fas fa-spinner fa-spin"></i> {{$t('downloading')}}...
                                    </template>
                                    <template v-else>
                                       {{$t('download-btn')}}
                                    </template>
                                </button>
                            </div>
                            <div class="ml-2 mt-2" v-if="canDownload || serverParams.category_id === 'all' || serverParams.category_id ">
                                <button :disabled="isLoading4" @click="viewReportData()" class="btn btn-info text-white r-btn">
                                    <template v-if="isLoading4">
                                        <i class="fas fa-spinner fa-spin"></i> {{$t('Generating')}}...
                                    </template>
                                    <template v-else>
                                       {{$t('view-btn')}}
                                    </template>
                                </button>
                            </div>
                            <div class="ml-2 mr-3 mt-2">
                                <button @click="resetFilters()" class="btn btn-warning text-white r-btn">{{ $t('reset-btn') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div v-if="serverParams.category_id === 'all'" class="report-message">
                <div class="text-secondary">For now, Report can be only be downloaded against all categories.</div>
            </div>
        </div> -->

        <div class="row mt-3">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('report') }}</h4>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table
                            ref="report-table" 
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :isLoading.sync="isLoading"
                            :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                            :rows="spots"
                            :columns="columns"
                            @on-search="searchFn"
                            :search-options="{enabled: true,searchFn: searchFn}"
                            :line-numbers="true">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'view_details'" class="text-nowrap">
                                    <b-button
                                        size="sm"
                                        @click="openReportSpotDetail(props.row.id)"
                                        class="mr-1 btn-info text-white"
                                    >{{ $t('view-btn') }}</b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>

        <SpotDetailsModal @openSpotDetail="openSpotDetail" @openReportSpotDetail="openReportSpotDetail" ref="spotDetails"></SpotDetailsModal>
    </section>
</template>

<script>
    import Vue from "vue";
    import API from "@/api";
    import {
        ClientSideRowModelModule
    } from '@ag-grid-community/client-side-row-model';
    import moment from 'moment';
    import Snotify, {
        SnotifyPosition
    } from "vue-snotify";
    import {
        validationMixin
    } from "vuelidate";
    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs
    } from "vuelidate/lib/validators";
    import SortedTablePlugin from "vue-sorted-table";
    import SpotDetailsModal from "../citizen/SpotDetailsModal.vue";
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    
    const options = {
        toast: {
            position: SnotifyPosition.rightTop
        }
    };
    
    Vue.use(Snotify, options);
    export default {
        name: "Reports",
        mixins: [validationMixin],
        components: {
            SpotDetailsModal
        },
        data() {
            return {
                version_id: null,
                selectedProjectId: null,
                projectOptions: [],
                form: {
                    client_id: JSON.parse(localStorage.getItem("client_id")),
                    date_from: '',
                    date_to: '',
                    category_id: '',
                    survey_id: '',
                    location_id: '',
                    spot_type_id: '',
                    question_data: 'yes',
                    // post_process: 'no',
                    prosync_weather_data:'no',
                    prosync_efforts_data:'no',
                },
                columnDefs: null,
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem("client_id")),
                    date_from: '',
                    date_to: '',
                    category_id: '',
                    survey_id: '',
                    selectedProjectId: '',
                    location_id: '',
                    spot_type_id: '',
                    question_data: 'yes',
                    // post_process: 'no',
                    prosync_weather_data: 'no',
                    prosync_efforts_data: 'no',
                    pro_sync: true,
                    columnFilters: {},
                    sort: [{
                        field: "id",
                        type: "desc"
                    }],
                    page: 1,
                    perPage: 10
                },
                
                columns: [{
                        label: "Spot ID",
                        field: "spot_id",
                        filterable: true
                    },
    
                    {
                        label: "Spotter",
                        field: "user_name",
                        filterable: true
                    },
                    {
                        label: "Date Spotted",
                        field: "spot_date",
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: "Type",
                        field: "spot_name",
                        sortable: true
                    },
                    {
                        label: "Location",
                        field: "location_name", 
                        sortable: true
                    },
                    {
                        label: "Project (Survey)",
                        field: "project_name", 
                        sortable: true
                    },
                    {
                        label: "Latitude",
                        field: "latitude", 
                        sortable: true
                    },
                    {
                        label: "Longitude",
                        field: "longitude", 
                        sortable: true
                    },
                    {
                        label: "Details",
                        field: "view_details",
                        sortable: false
                    }
                ],
                isLoading: false,
                isLoading2:false,
                isLoading3:false,
                isLoading4:false,
                isDownImageTemplate: false,
                rowData: null,
                spots: [],
                categories: [],
                surveys: [],
                locations: [],
                spotTypes: [],
                underDevelop: true,
                canDownload: false,
                questions: [],
                postQuestions: [],
                totalRecords: 0,
                spotDetail: [],
                spotDetailImages: [],
                serverUrl: ''
            }
        },
        validations: {
            serverParams: {
                category_id: {
                    required
                }
            }
        },
        methods: {
            getNames() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.getProjectName(
                    data,
                    response => {
                        this.projectOptions = response.data.map(project => ({
                            id: project.id,
                            name: project.project_name
                        }));

                        // Wait for projectOptions to be populated
                        this.$nextTick(() => {
                            const selectedProject = this.projectOptions.find(project => project.id === this.projectID);
                            if (selectedProject) {
                                this.selectedProjectId = selectedProject.id;
                                this.selectedProjectName = selectedProject.name;
                            }
                        });
                    },
                    err => {
                        console.error('Error fetching project names:', err);
                    }
                );
            },
            onCategoryChange() {
                console.log('this is the main div');
                this.$refs['report-table'].reset();
            },
            checkImage(image) {
                let found = image.search('.mp3');
                if (found === -1) {
                    return true;
                } else {
                    return false;
                }
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format("YYYY-MM-DD HH:mm");
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.getReportsData();
            },
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.getReportsData();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field
                    }]
                });
                this.getReportsData();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.getReportsData();
            },
            searchFn(params) {
                this.updateParams({
                    searchTerm: params
                });
                this.getReportsData();
                this.isLoading2 = false;
            },
            formatStatus(status) {
                if (status === "A") {
                    return true;
                } else {
                    return false;
                }
            },
            formatUser(value) {},
            formatLocation(value) {
                return value.name;
            },
            onGridReady(params) {
                this.gridApi = params.api;
                this.gridColumnApi = params.columnApi;
            },
            getProSyncReportFilters() {
                let data = {
                    client_id: this.serverParams.client_id
                };
    
                API.getProSyncReportFilters(
                    data,
                    data => {
                        this.version_id = data.version_id;
                        this.categories = data.data.categories;
                        this.locations = data.data.locations;
                        this.surveys = data.data.surveys;
                        console.log('this.surveys29', this.surveys);
                        this.spotTypes = data.data.spot_types;
                    },
                    err => {}
                );
            },
            downloadReportData() {
                this.$v.serverParams.$touch();
                if (!this.serverParams.category_id) {
                    return;
                }
                this.isLoading3 = true;
                API.downloadReportData(
                    this.serverParams,
                    data => {
                        this.isLoading3 = false;
                        const href = URL.createObjectURL(data);
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'report.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.$snotify.success('Report downloaded successfully.');
                    },
                    err => {
                        this.isLoading3 = false
                    }
                );
            },
            viewReportData() {
                this.$v.serverParams.$touch();
                if (!this.serverParams.category_id) {
                    return;
                }
                let resolvedRoute = this.$router.resolve({ name: 'ReportView', query: {serverParams: JSON.stringify(this.serverParams)}});
                window.open(resolvedRoute.href, '_blank');
            },
            // downloadImagesUploadData() {
            //     this.$v.serverParams.$touch();
            //     if (!this.serverParams.category_id) {
            //         return;
            //     }
            //     this.isDownImageTemplate = true;
            //     API.downloadImagesUploadData(
            //         this.serverParams,
            //         data => {
            //             this.isDownImageTemplate = false;
            //             const href = URL.createObjectURL(data);
            //             const link = document.createElement('a');
            //             link.href = href;
            //             link.setAttribute('download', 'image-upload-template.xlsx');
            //             document.body.appendChild(link);
            //             link.click();
            //             document.body.removeChild(link);
            //             this.$snotify.success('Image template downloaded successfully.');
            //         },
            //         err => {
            //             this.isDownImageTemplate = false;
            //         }
            //     );
            // },
            getReportsData(e = null) {
                this.$v.serverParams.$touch();
                if (!this.serverParams.category_id) {
                    return;
                }
                this.isLoading2 = true;
                API.getProSyncReportsData(
                    this.serverParams,
                    data => {
                        console.log('this.serverParams',this.serverParams);
                        this.isLoading2 = false;
                        this.canDownload = true;
                        this.totalRecords = data.spots.total;
                        console.log('this.totalRecords', this.totalRecords);
                        this.spots = data.spots.data;
                        console.log('this.spots', this.spots);

                        if (data.status === 200 && e === 'filter') {
                            this.$snotify.success(data.message);
                        }
                    },
                    err => {
                        this.isLoading2 = false;
                    }
                );
            },
            resetFilters() {
                this.canDownload = false;
                this.serverParams.date_from = "";
                this.serverParams.date_to = "";
                this.serverParams.category_id = "";
                this.serverParams.location_id = "";
                this.serverParams.survey_id = "";
                this.serverParams.spot_type_id = "";
                // this.serverParams.post_process = "no";
                this.serverParams.prosync_weather_data = "no";
                this.serverParams.prosync_efforts_data = "no";
                this.totalRecords = 0;
                this.spots = [];
                 this.$refs['report-table'].reset();
                 this.$snotify.success('Report reset successfully.');
            },
            openReportSpotDetail(id,event) {
                this.$refs.spotDetails.openReportSpotDetail(id,false)
            },
        },
        computed: {
        },
        beforeMount() {
            this.columnDefs = [{
                    headerName: "Spot ID",
                    field: "spot_id",
                    sortable: true,
                    filter: true,
                    checkboxSelection: true
                },
                {
                    label: "Spotter",
                    field: "user_name",
                    filterable: true
                },
                {
                    headerName: "Date Spotted",
                    cellRenderer: (data) => {
                        return moment(data.spot_date).format('MM/DD/YYYY HH:mm:ss');
                    },
                    sortable: true,
                },
                {
                    label: "Type",
                    field: "spot_name",
                    sortable: true
                },
                {
                    label: "Location",
                    field: "location_name", 
                    sortable: true
                },
            ];
        },
        mounted() {
            this.getProSyncReportFilters();
            // this.getReportsData();
            this.getNames();
        }
    };
</script>

<style lang="scss">
    @import "~ag-grid-community/styles/ag-grid.css";
    @import "~ag-grid-community/styles/ag-theme-alpine.css";
    .mat-select {
        height: 46px;
    }
        .new-table-responsive  tr td span {
        white-space: pre-wrap;
        line-height: 1.2;
    }

    .new-date-form {
        flex-wrap: wrap;
    }
    .new-col {
        padding-left: 5px !important;
        padding-right: 0 !important;
    }

    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }

    @media (max-width: 767px) {
        .ml-1 {
            margin-left: 0px !important;
        }
        .new-col {
            padding: 0 !important;
        }
        .m-col {
            margin-top: 0.5rem;
        }
    }

    @media (max-width: 580px) {
        .footer__navigation .footer__navigation__page-btn {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .vgt-wrap__footer .footer__navigation__page-info {
            margin: 0 !important;
        }
        .vgt-wrap__footer .footer__navigation__page-btn .chevron {
            width: 14px;
        }
    }

    @media (max-width: 375px) {
        .r-btn {
            padding: 10px;
        }
    }
</style>